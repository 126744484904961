module.exports = {
	pohutukawa: '#641823',
	direWolf: '#272727',
	yellowSand: '#A28C44',
	silverLake: '#DEDEDE',
	americanSilver: '#CFCFCF',
	greyOfDarkness: '#A1A2A3',
	silverFiligree: '#7D7E80',
	zhenZhuBaiPearl: '#f8f8f8',
	white: '#ffffff',
	transparent: `rgba(255,255,255,0)`,
};

// Might come in handy: https://colornamer.netlify.com/
