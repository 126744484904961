import React, { Component } from 'react';
import styled from 'styled-components';

import FormStyle from '../../styles/formStyle';
import AccentLine from '../AccentLine';

const StyledForm = styled(FormStyle)`
	color: ${(props) => props.theme.colors.lightest};

	h2 {
		color: ${(props) => props.theme.colors.lightest};
		text-transform: none;
	}

  .instructions {
    font-size: 1.4rem;
  }

	.control {
		@media (min-width: 769px) {
			margin-right: 4rem;
		}
	}

	input,
	textarea,
  select {
		border-bottom-color: ${(props) => props.theme.colors.lightest};
		color: ${(props) => props.theme.colors.lightest};
	}

  option {
    background-color: ${(p) => p.theme.colors.secondary};
  }

	::placeholder {
		color: ${(props) => props.theme.colors.textGrey};
	}

	[type='submit'] {
		color: ${(props) => props.theme.colors.lightest};
		border-color: ${(props) => props.theme.colors.lightest};

		&:hover {
			/* color: ${(props) => props.theme.colors.secondary};
			background-color: ${(props) => props.theme.colors.lightest}; */
		}

		&::before {
			background-color: ${(props) => props.theme.colors.lightest};
		}

		&::after {
			border-right-color: ${(props) => props.theme.colors.lightest};
			border-bottom-color: ${(props) => props.theme.colors.lightest};
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class BuyPageFooterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
			referralNotesOpen: false,
			otherNotesOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });

		if (e.target.name === 'referral') {
			if (e.target.value === 'Referral') {
				this.setState({ referralNotesOpen: true });
			} else {
				this.setState({ referralNotesOpen: false });
			}
			if (e.target.value === 'Other') {
				this.setState({ otherNotesOpen: true });
			} else {
				this.setState({ otherNotesOpen: false });
			}
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(this.setState({ isSubmitted: true }))
			.catch((error) => alert(error));
	};

	thePage = this.props.page;

	render() {
		let theTitle = '';
		let formName = '';
		let id = '';

		switch (this.props.page) {
			case 'sell':
				theTitle = 'Interested in Selling Your Home?';
				formName = 'Sell Your Home';
				id = 'sell-your-home-form';
				break;
			case 'buy':
				theTitle = 'Get notified when your dream home hits the market!';
				formName = 'Buy a Home';
				id = 'buy-a-home-form';
				break;
			default:
				theTitle = 'Get in touch with us';
				formName = 'Footer Contact';
				id = 'footer-contact-form';
		}

		if (!!this.state.isSubmitted) {
			return (
				<div className="columns is-centered">
					<div className="column is-narrow has-text-white">
						<h2 className="has-text-centered">Thanks!</h2>
						<p className="has-text-centered">
							We have received your submission.
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<StyledForm
					name={formName}
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
					id={id}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value={formName} />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={this.handleChange} />
						</label>
					</div>
					<div className="columns">
						<div className="column">
							<h2>{theTitle}</h2>
							<AccentLine />
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<p className="instructions">
								Fill out the form below with your new home requirements to get
								notified when your perfect home gets listed
							</p>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'first-name'}
									onChange={this.handleChange}
									id={'first-name'}
									required={true}
									placeholder={'Jane'}
								/>
							</div>
							<label htmlFor={'first-name'}>First Name</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'last-name'}
									onChange={this.handleChange}
									id={'last-name'}
									required={true}
									placeholder={'Smith'}
								/>
							</div>
							<label htmlFor={'last-name'}>Last Name</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'email'}
									name={'email'}
									onChange={this.handleChange}
									id={'email'}
									required={true}
									placeholder={'janesmith@email.com'}
								/>
							</div>
							<label htmlFor={'email'}>Email</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'phone'}
									onChange={this.handleChange}
									id={'phone'}
									placeholder={'(705) 555-5555'}
								/>
							</div>
							<label htmlFor={'phone'}>Phone</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'city'}
									onChange={this.handleChange}
									id={'city'}
									required={false}
									placeholder={'Your City'}
								/>
								<label htmlFor={'city'}>City</label>
							</div>
						</div>
						<div className="column">
							<div className="control">
								<div className="columns is-mobile">
									<div className="column">
										<select
											name={'bedrooms'}
											id={'bedrooms'}
											onChange={this.handleChange}
											defaultValue={'0'}
											required={false}
										>
											<option value="0" disabled>
												— —
											</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select>
										<label htmlFor={'bedrooms'}>Bedrooms</label>
									</div>
									<div className="column">
										<select
											name={'bathrooms'}
											id={'bathrooms'}
											onChange={this.handleChange}
											defaultValue={'0'}
											required={false}
										>
											<option value="0" disabled>
												— —
											</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select>
										<label htmlFor={'bathrooms'}>Bathrooms</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'square-feet'}
									onChange={this.handleChange}
									id={'square-feet'}
									required={false}
									placeholder={'Square Feet'}
								/>
							</div>
							<label htmlFor={'square-feet'}>Square Feet</label>
						</div>
						<div className="column">
							<div className="control">
								<textarea
									name={'notes'}
									onChange={this.handleChange}
									id={'notes'}
									required={false}
									rows="1"
									placeholder={'Additional Notes'}
								/>
							</div>
							<label htmlFor={'notes'}>Notes</label>
						</div>
					</div>
					<div className="columns">
						<div className="column is-half">
							<div className="control">
								<select
									name={'referral'}
									id={'referral'}
									onChange={this.handleChange}
									defaultValue={''}
									required={true}
								>
									<option value="" disabled>
										— —
									</option>
									<option value="Lawn Sign / For Sale Sign">
										Lawn Sign / For Sale Sign
									</option>
									<option value="Facebook / Instagram">
										Facebook / Instagram
									</option>
									<option value="Google Ad">Google Ad</option>
									<option value="Radio">Radio</option>
									<option value="Billboard">Billboard</option>
									<option value="Bus Stop">Bus Stop</option>
									<option value="Referral">Referral</option>
									<option value="Other">Other</option>
								</select>
								<label htmlFor={'referral'}>How did you hear about us?</label>
							</div>
						</div>
						<div className="column">
							{this.state.referralNotesOpen && (
								<>
									<div className="control">
										<textarea
											name={'referral-notes'}
											onChange={this.handleChange}
											id={'referral-notes'}
											required={true}
											rows="1"
											placeholder={'Tell Us More'}
										/>
									</div>
									<label htmlFor={'referral-notes'}>Referral Notes</label>
								</>
							)}
							{this.state.otherNotesOpen && (
								<>
									<div className="control">
										<textarea
											name={'other-notes'}
											onChange={this.handleChange}
											id={'other-notes'}
											required={true}
											rows="1"
											placeholder={'Tell Us More'}
										/>
									</div>
									<label htmlFor={'other-notes'}>Other Notes</label>
								</>
							)}
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<div className="checkbox-wrapper">
									<input
										type={'checkbox'}
										name={'notify-me'}
										id={'notify-me'}
										onChange={this.handleChange}
										required={true}
									/>
									<div className="checkbox"></div>
									<label htmlFor={'notify-me'}>
										Notify me of pre-market homes in my area!
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-narrow">
							<button className="button button-1" type="submit">
								Submit
							</button>
						</div>
					</div>
				</StyledForm>
			);
		}
	}
}
