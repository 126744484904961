import React, { Component } from 'react';

import Icon from '-!svg-react-loader?name=Facebook!../../img/social/facebook.svg';

class Facebook extends Component {
	render() {
		return <Icon />;
	}
}

export default Facebook;
