import React, { Component } from 'react';

import Icon from '-!svg-react-loader?name=Linkedin!../../img/social/linkedin.svg';

class Linkedin extends Component {
	render() {
		return <Icon />;
	}
}

export default Linkedin;
