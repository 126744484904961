import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
  }

  body {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 1.8rem;
    font-weight: ${(props) => props.theme.fonts.weights.regular};
    color: ${(props) => props.theme.colors.secondary};
    overflow: hidden;
  }
`;

export default GlobalStyle;
