import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import mdToHtml from '../../util/mdToHtml';
import Arrow from '-!svg-react-loader?name=LearnMoreArrow!../../img/learn-more-arrow.svg';

const Banner = styled.aside`
	padding: 0 2rem;
	background-color: ${({ theme }) => theme.colors.primary};
	position: relative;
	z-index: 999;

	.banner-inner {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;

		.text-wrapper {
			color: ${({ theme }) => theme.colors.lightest};
			font-family: ${({ theme }) => theme.fonts.secondary};
			font-size: 1.6rem;
			text-align: center;
			line-height: 1.375;
			padding: 1rem 2rem;

			@media (min-width: 768px) {
				font-size: 2rem;
			}
		}
	}
`;

const ButtonWrapper = styled.div`
	margin-right: -2rem;

	@media (min-width: 768px) {
		margin-right: 0;
	}

	a {
		height: 100%;
		line-height: 1;

		span {
			margin-right: 1rem;
			display: none;

			@media (min-width: 768px) {
				display: inline-block;
			}
		}

		svg {
			path,
			line {
				transition: 300ms;
			}
		}

		&:hover {
			svg {
				path,
				line {
					stroke: ${({ theme }) => theme.colors.tertiary};
				}
			}
		}
	}
`;

const BannerButton = ({ children, link }) => {
	return (
		<ButtonWrapper>
			<a className="button button-5" href={link}>
				<span>{children}</span>
				<Arrow />
			</a>
		</ButtonWrapper>
	);
};

export const AlertBanner = ({ page }) => {
	const data = useStaticQuery(graphql`
		query {
			markdownRemark(frontmatter: { name: { eq: "Settings" } }) {
				frontmatter {
					alertBanner {
						isActive
						text
						button {
							buttonText
							buttonLink
						}
					}
					originalFields {
						buttonLink
					}
				}
			}
		}
	`);

	const settings = data.markdownRemark.frontmatter;
	let isDisplayed = true;

	switch (page) {
		case 'promo':
			isDisplayed = false;
			break;

		default:
			break;
	}

	return settings.alertBanner.isActive && isDisplayed ? (
		<Banner>
			<div className="container">
				<div className="banner-inner">
					<div
						className="text-wrapper"
						dangerouslySetInnerHTML={{
							__html: mdToHtml(settings.alertBanner.text),
						}}
					/>
					{settings.alertBanner.button.buttonText &&
					settings.originalFields.buttonLink ? (
						<BannerButton link={settings.originalFields.buttonLink}>
							{settings.alertBanner.button.buttonText}
						</BannerButton>
					) : null}
				</div>
			</div>
		</Banner>
	) : null;
};
