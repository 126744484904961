import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/theme/theme';
import '../styles/all.scss';
import GlobalStyle from '../styles/GlobalStyle';

import Footer from './Footer';
import ContactFooter from './ContactFooter';
import Header from './Header';
import { AlertBanner } from './alert-banner';

const TemplateWrapper = ({ children, page }) => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<AlertBanner page={page} />
				<div style={{ position: 'relative' }}>
					<Header page={page} />
					<main role="main">{children}</main>
					{page === 'contact' || page === 'our-team' ? (
						<ContactFooter />
					) : (
						<Footer page={page} />
					)}
				</div>
			</>
		</ThemeProvider>
	);
};

export default TemplateWrapper;
