import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import logo from '../img/parker-coulter-footer-logo.png';

const StyledFooter = styled.footer`
	padding: 6rem 1.9rem 2rem;
	background: #272727;
	background: linear-gradient(to bottom, #272727 0%, #151515 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#272727', endColorstr='#151515',GradientType=0 ); /* IE6-9 */

	@media (max-width: 768px) {
		padding-top: 6rem;
	}

	.footer-inner {
		width: 1600px;
		max-width: 100%;
		margin: 0 auto;
	}

	.contact-column {
		@media (min-width: 769px) {
			padding-left: 5%;
		}
	}

	.logo-link {
		img {
			width: 28rem;
			margin-bottom: 1rem;
		}
	}

	.pages {
		margin-top: 5rem;

		.page-list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li {
				/* display: inline-block; */
				padding: 0.5rem 2rem;
				/* position: relative; */

				a {
					font-size: 1.4rem;
					letter-spacing: 1.8px;
					font-weight: ${(props) => props.theme.fonts.weights.bold};
					color: ${(props) => props.theme.colors.lightest};
					text-transform: uppercase;
					transition: 300ms;
					white-space: nowrap;
					padding: 0;

					&:hover {
						color: ${(props) => props.theme.colors.tertiary};
						text-decoration: underline;
						background: none;
					}
				}

				+ li {
					&::before {
						/* content: '';
						height: 1.5rem;
						width: 0.1rem;
						background: ${(props) => props.theme.colors.lightest};
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%); */
					}
				}
			}
		}
	}
	.footer-bottom {
		color: ${(props) => props.theme.colors.lightest};
		padding-top: 3rem;

		a {
			color: inherit;
		}
	}
`;

const ContactFooter = () => (
	<StyledFooter>
		<div className="footer-inner">
			<div className="columns is-vcentered">
				<div className="column is-4">
					<div className="has-text-centered">
						<Link className="logo-link" to="/">
							<img src={logo} alt="Parker Coulter Home Page" />
						</Link>
					</div>
				</div>
				<div className="column is-8 contact-column">
					<section className="pages">
						<ul className="page-list">
							<li>
								<Link to="/sell-your-home">Sell Your Home</Link>
							</li>
							<li>
								<Link to="/buy-a-home">Buy a Home</Link>
							</li>
							<li>
								<Link to="/listings">Listings</Link>
							</li>
							<li>
								<Link to="/our-team">Our Team</Link>
							</li>
							<li>
								<Link to="/blog">Blog</Link>
							</li>
						</ul>
					</section>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="columns">
					<div className="column has-text-centered">
						<small>
							&copy; Parker Coulter Realty, {new Date().getFullYear()}. |{' '}
							<Link to="/privacy-policy">Privacy Policy</Link>
						</small>
					</div>
				</div>
			</div>
		</div>
	</StyledFooter>
);

export default ContactFooter;
