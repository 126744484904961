import React, { Component } from 'react';

import Icon from '-!svg-react-loader?name=Twitter!../../img/social/twitter.svg';

class Twitter extends Component {
	render() {
		return <Icon />;
	}
}

export default Twitter;
