const colors = require('./colors');
const fonts = require('./fonts');

module.exports = {
	colors: {
		primary: colors.pohutukawa,
		secondary: colors.direWolf,
		tertiary: colors.yellowSand,
		quaternary: colors.silverLake,
		textGrey: colors.greyOfDarkness,
		textDarkerGrey: colors.silverFiligree,
		lightBgGrey: colors.zhenZhuBaiPearl,
		borderGrey: colors.americanSilver,
		lightest: colors.white,
		transparent: colors.transparent,
	},
	fonts: {
		primary: fonts.quasimoda,
		secondary: fonts.ivyMode,
		weights: {
			regular: 400,
			semiBold: 600,
			bold: 700,
		},
	},
	icons: {
		video: `<svg
				xmlns="http://www.w3.org/2000/svg"
				width="113"
				height="113"
				viewBox="0 0 113 113"
			>
				<g id="Group_1" data-name="Group 1" transform="translate(-895 -508)">
					<path
						id="video-triangle"
						data-name="Icon ionic-ios-play"
						d="M9,8.1v49.27a1.269,1.269,0,0,0,1.9,1.169L49.955,33.9a1.4,1.4,0,0,0,0-2.322L10.9,6.943A1.257,1.257,0,0,0,9,8.1Z"
						transform="translate(925.84 530.697)"
						fill="#fff"
					/>
					<g
						id="video-ring"
						data-name="Ellipse 1"
						transform="translate(895 508)"
						fill="none"
						stroke="#fff"
						stroke-width="8"
					>
						<circle cx="56.5" cy="56.5" r="56.5" stroke="none" />
						<circle cx="56.5" cy="56.5" r="52.5" fill="none" />
					</g>
				</g>
			</svg>`,
		close: `<svg
				xmlns="http://www.w3.org/2000/svg"
				width="15.557"
				height="15.556"
				viewBox="0 0 15.557 15.556"
			>
				<g
					id="Group_232"
					data-name="Group 232"
					transform="translate(4834.278 -182.722)"
				>
					<rect
						id="Rectangle_132"
						data-name="Rectangle 132"
						width="19"
						height="3"
						transform="translate(-4834.278 196.157) rotate(-45)"
						fill="#fff"
					/>
					<rect
						id="Rectangle_133"
						data-name="Rectangle 133"
						width="19"
						height="3"
						transform="translate(-4832.157 182.722) rotate(45)"
						fill="#fff"
					/>
				</g>
			</svg>`,
	},
};
