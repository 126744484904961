import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import AccentLine from '../components/AccentLine';
import SocialIcons from '../components/social/SocialIcons';
import FooterContact from '../components/forms/FooterContact';
import BuyFooter from '../components/forms/BuyFooter';

import logo from '../img/parker-coulter-footer-logo.png';

const StyledFooter = styled.footer`
	padding: 8.5rem 1.9rem 2rem;
	background: #272727;
	background: linear-gradient(to bottom, #272727 0%, #151515 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#272727', endColorstr='#151515',GradientType=0 ); /* IE6-9 */

	@media (max-width: 768px) {
		padding-top: 6rem;
	}

	.footer-inner {
		width: 1600px;
		max-width: 100%;
		margin: 0 auto;
	}

	.left-column {
		@media (max-width: 767px) {
			display: flex;
			flex-direction: column;

			.footer-contact-mobile {
				order: 1;
			}

			.footer-details {
				order: 2;
				padding-top: 8rem;
			}

			.pages {
				order: 3;
			}

			.serving {
				order: 4;
			}
		}
	}

	.contact-column {
		@media (min-width: 769px) {
			padding-left: 5%;
		}
	}

	.logo-link {
		img {
			width: 24rem;
			margin-bottom: 1rem;
		}

		+ [class^='AccentLine'] {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.contact {
		margin-top: 2.5rem;

		.contact-list {
			li {
				a {
					font-family: ${(props) => props.theme.fonts.secondary};
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					font-size: 1.8rem;
					letter-spacing: 3px;
					color: ${(props) => props.theme.colors.lightest};
					transition: 300ms;

					address {
						font-style: normal;
						margin-bottom: 0.5em;

						.no-break {
							white-space: nowrap;
						}
					}

					&:hover {
						color: ${(props) => props.theme.colors.tertiary};
						text-decoration: underline;
					}

					@media (max-width: 1279px) {
						font-size: 1.8rem;
						letter-spacing: 2.16px;
					}
				}
			}
		}
	}

	.social {
		margin-top: 2.5rem;
		ul {
			li {
				a {
					svg {
						height: 2.4rem;
					}
				}
			}
		}
	}

	.footer-contact-mobile {
		margin-top: 3rem;
		
		@media (min-width: 769px) {
			display: none;
		}
	}

	.footer-contact-desktop {
		@media (max-width: 768px) {
			display: none;
		}
	}

	.pages {
		margin-top: 5rem;

		.page-list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li {
				/* display: inline-block; */
				padding: 0.5rem 1rem;
				/* position: relative; */

				a {
					font-size: 1.4rem;
					letter-spacing: 1.8px;
					font-weight: ${(props) => props.theme.fonts.weights.bold};
					color: ${(props) => props.theme.colors.lightest};
					text-transform: uppercase;
					transition: 300ms;
					white-space: nowrap;
					padding: 0;

					&:hover {
						color: ${(props) => props.theme.colors.tertiary};
						text-decoration: underline;
						background: none;
					}
				}

				+ li {
					/* &::before {
						content: '';
						height: 1.5rem;
						width: 0.1rem;
						background: ${(props) => props.theme.colors.lightest};
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					} */
				}
			}
		}
	}

	.serving {
		margin-top: 2rem;

		p {
			font-size: 1.2rem;
			color: ${(props) => props.theme.colors.lightest};
			text-transform: uppercase;
			letter-spacing: 0.22px;
			text-align: center;
			width: 400px;
			max-width: 100%;
			margin: 0 auto;

			.no-break {
				white-space: nowrap;
			}
		}
	}

	.footer-bottom {
		color: ${(props) => props.theme.colors.lightest};
		padding-top: 3rem;

		a {
			color: inherit;
		}
	}
`;

const Footer = ({ page }) => {
	return (
		<StyledFooter>
			<div className="footer-inner" id="contact">
				<div className="columns is-vcentered">
					<div className="column is-4 left-column">
						<div className="footer-details">
							<div className="has-text-centered">
								<Link className="logo-link" to="/">
									<img src={logo} alt="Parker Coulter Home Page" />
								</Link>
								<AccentLine />
							</div>
							<section className="contact">
								<ul className="contact-list has-text-centered">
									<li>
										<a
											href="https://www.google.com/maps/place/Parker+Coulter+Realty+Brokerage+Inc./@44.3955378,-79.6411797,15z/data=!4m5!3m4!1s0x0:0xb64f324089cc7f40!8m2!3d44.3955378!4d-79.6411797"
											target="_blank"
											rel="noopener noreferrer"
										>
											<address>
												Parker Coulter Realty Brokerage Inc.
												<br />
												360 Shanty Bay Rd, Barrie, ON
												<span className="no-break"> L4M 1E7</span>
											</address>
										</a>
									</li>
									<li>
										<a href="mailto:contact@parkercoulter.com">
											contact@parkercoulter.com
										</a>
									</li>
									<li>
										<a href="tel:+17057229111">705-722-9111</a>
									</li>
								</ul>
							</section>
							<section className="social">
								<SocialIcons />
							</section>
						</div>
						<section className="footer-contact-mobile">
							{page === 'buy' ? (
								<BuyFooter page={page} />
							) : (
								<FooterContact page={page} />
							)}
						</section>
						<section className="pages">
							<ul className="page-list">
								<li>
									<Link to="/listings">Listings</Link>
								</li>
								<li>
									<Link to="/buy-a-home">Buy</Link>
								</li>
								<li>
									<Link to="/sell-your-home">Sell</Link>
								</li>
								<li>
									<Link to="/contact">Contact Us</Link>
								</li>
							</ul>
						</section>
						<section className="serving">
							<p>
								Serving Barrie, Bradford, Essa, Innisfil, Muskoka, Newmarket,
								Orillia, Oro-Medonte, Parry Sound,
								<span className="no-break"> and the GTA</span>
							</p>
						</section>
					</div>
					<div className="column is-8 contact-column">
						<section className="footer-contact-desktop">
							{page === 'buy' ? (
								<BuyFooter page={page} />
							) : (
								<FooterContact page={page} />
							)}
						</section>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="columns">
						<div className="column has-text-centered">
							<small>
								&copy; Parker Coulter Realty, {new Date().getFullYear()}. |{' '}
								<Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms">Terms</Link>
							</small>
						</div>
					</div>
				</div>
			</div>
		</StyledFooter>
	);
};

export default Footer;
