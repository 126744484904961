import styled from 'styled-components';

const AccentLine = styled.span`
	display: block;
	width: 6rem;
	height: 0.2rem;
	margin-top: 1.5rem;
	background-color: ${(props) =>
		props.color ? props.color : props.theme.colors.quaternary};
	${(props) =>
		props.align === 'center' ? 'margin: 2.5rem auto 0;' : undefined}
`;

export default AccentLine;
