import styled from 'styled-components';

export default styled.form`
	h2 {
		font-family: ${(props) => props.theme.fonts.secondary};
		font-weight: ${(props) => props.theme.fonts.weights.regular};
		font-size: 2.5rem;
		letter-spacing: 3px;
	}

	label {
		display: inline-block;
		font-size: 1.5rem;
		letter-spacing: 1.8px;
		text-transform: uppercase;
		margin-top: 1.5rem;
	}

	input,
	textarea,
	select {
		background: none;
		border: none;
		border-bottom: 1px solid;
		width: 100%;
		padding: 0.75rem 0;
		margin-top: 2rem;
		font-size: 1.8rem;
		border-radius: 0;

		&::placeholder {
			text-transform: uppercase !important;
		}
	}

	textarea {
		resize: none;
	}

	.checkbox-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	[type='checkbox'] {
		opacity: 0;
		position: absolute;
		margin-top: 0;
		height: 100%;

		& ~ .checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 1rem;
			width: 1.5rem;
			height: 1.5rem;
			border: 1px solid ${(p) => p.theme.colors.lightest};
			border-radius: 50%;
			pointer-events: none;
			margin-top: 1.5rem;

			&::before {
				content: '';
				width: 0.9rem;
				height: 0.9rem;
				background-color: rgba(255, 255, 255, 0);
				border-radius: 50%;
			}
		}

		&:checked ~ .checkbox {
			&::before {
				background-color: ${(p) => p.theme.colors.lightest};
			}
		}

		& ~ label {
			font-size: 1.6rem;
			text-transform: none;
		}
	}

	[type='submit'] {
		border: 1px solid;
		border-radius: 0;
		font-size: 1.8rem;
		letter-spacing: 1.44px;
		text-transform: uppercase;
		margin-top: 3rem;
		background: none;
		transition: 300ms;
		position: relative;

		&::before,
		&::after {
			transition: 500ms cubic-bezier(0.12, -1.05, 0.72, 0.9);
		}

		&::before {
			content: '';
			height: 0.1rem;
			width: 3rem;
			right: -2rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&::after {
			content: '';
			height: 1.5rem;
			width: 1.5rem;
			right: -1.9rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(-45deg);
			border-right: 1px solid;
			border-bottom: 1px solid;
		}

		&:hover {
			&::before {
				transform: translateY(-50%) translateX(1rem);
			}

			&::after {
				transform: translateY(-50%) translateX(1rem) rotate(-45deg);
			}
		}
	}
`;
