/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, image, slug }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
						siteKeywords
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const metaKeywords = keywords.length
		? keywords
		: site.siteMetadata.siteKeywords;
	const titleTemplate = `${title}`
	const ogImage = image || `${site.siteMetadata.siteUrl}/media/og-image.jpg`;
	const ogUrl = slug
		? `${site.siteMetadata.siteUrl}${slug}`
		: site.siteMetadata.siteUrl;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={titleTemplate}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: titleTemplate,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `business.business`,
				},
				{
					property: `og:url`,
					content: ogUrl,
				},
				{
					property: `og:image`,
					content: ogImage,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: titleTemplate,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]
				.concat(
					metaKeywords.length > 0
						? {
							name: `keywords`,
							content: metaKeywords.join(`, `),
						}
						: []
				)
				.concat(meta)}
			script={[{
				type: 'application/ld+json',
				innerHTML: `{
	"@context": "http://schema.org",
	"@type": "LocalBusiness",
	"name": "Parker Coulter Realty Brokerage Inc.",
	"telephone": "705-722-9111",
	"email": "contact@parkercoulter.com",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "360 Shanty Bay Rd",
		"addressLocality": "Barrie",
		"addressRegion": "ON",
		"postalCode": "L4M 1E7"
	},
	"review": {
		"@type": "Review",
		"author": {
			"@type": "Person",
			"name": "Bret D."
		},
		"reviewBody": "Parker really came through for us! He worked around our busy schedules and kept us off the ledge after the first deal didn&#39;t work out. We found our dream home shortly after and Parker worked tirelessly to get us a great deal on our new home, which wouldn&#39;t have been possible if he didn&#39;t do so well on selling ours. He&#39;s worth the standard agent fee, and charges much less...what great value! If you&#39;re selling or buying, you&#39;re in great hands with this brokerage"
	}
}`
			}]}
		>
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/media/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				href="/media/favicon-32x32.png"
				sizes="32x32"
			/>
			<link
				rel="icon"
				type="image/png"
				href="/media/favicon-16x16.png"
				sizes="16x16"
			/>
			<link rel="stylesheet" href="https://use.typekit.net/nnn0jrm.css" />
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
};

export default SEO;
