import React from 'react';
import styled from 'styled-components';

import Facebook from './Facebook';
import Instagram from './Instagram';
import Twitter from './Twitter';
import Linkedin from './Linkedin';

const StyledSocialIcons = styled.ul`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;

	li {
		display: flex;

		svg {
			height: 1.75rem;
			width: auto;
			transition: 300ms;

			path,
			circle {
				transition: 300ms;
			}
		}

		+ li {
			margin-left: 1.5rem;

			@media (max-width: 1087px) {
				margin-left: 1.5rem;
			}
		}

		&:hover {
			svg {
				transform: scale(1.1);

				path,
				circle {
					fill: ${(props) => props.theme.colors.tertiary};
				}
			}
		}

		a {
			display: inline-block;
		}
	}
`;

const SocialIcons = () => {
	return (
		<StyledSocialIcons>
			<li>
				<a
					href="https://www.facebook.com/ParkerCoulter/"
					target="_blank"
					rel="noopener noreferrer"
					title="Parker Coulter Real Estate on Instagram"
				>
					<Facebook />
				</a>
			</li>
			<li>
				<a
					href="https://www.instagram.com/parkercoulterrealestate/"
					target="_blank"
					rel="noopener noreferrer"
					title="Parker Coulter Real Estate on Instagram"
				>
					<Instagram />
				</a>
			</li>
			<li>
				<a
					href="https://twitter.com/ParkerCoulter"
					target="_blank"
					rel="noopener noreferrer"
					title="Parker Coulter Real Estate on Twitter"
				>
					<Twitter />
				</a>
			</li>
			<li>
				<a
					href="https://www.linkedin.com/in/parkercoulter/"
					target="_blank"
					rel="noopener noreferrer"
					title="Parker Coulter Real Estate on Linkedin"
				>
					<Linkedin />
				</a>
			</li>
		</StyledSocialIcons>
	);
};

export default SocialIcons;
